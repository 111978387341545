import type {CredentialCreationOptionsJSON, PublicKeyCredentialWithAttestationJSON} from '@github/webauthn-json'

import {create, supported} from '@github/webauthn-json'
import {observe} from 'selector-observer'
import {on} from 'delegated-events'
import {parseHTML} from '../parse-html'
import {remoteForm} from '@github/remote-form'
import sudo from '../sudo'

interface RegistrationResponse {
  nickname?: string
  registered_trusted_devices_count?: string
  webauthn_register_request?: JSON
  registration?: string
  error?: string
}

/******* Reading/writing registration form data ********/

function u2fForm(): HTMLFormElement | null {
  return document.querySelector<HTMLFormElement>('.js-add-u2f-registration-form')
}

const registerRequestDataAttribute = 'data-webauthn-register-request'

function updateDataAttribute(data: RegistrationResponse): void {
  if (data.webauthn_register_request) {
    u2fForm()?.setAttribute(registerRequestDataAttribute, JSON.stringify(data.webauthn_register_request))
  }
}

/******* DOM listeners ********/

remoteForm('.js-u2f-registration-delete', async function (form, wants) {
  const registration = form.closest<HTMLElement>('.js-u2f-registration')!
  const button = registration.querySelector<HTMLButtonElement>('.js-u2f-registration-delete-button')!
  // Trigger a spinner while deleting a U2F device
  if (button) {
    button.disabled = true
  } else {
    registration.classList.add('is-sending')
  }

  const response = await wants.json()

  // Remove the U2F registration from the list once deleted
  updateDataAttribute(response.json)
  registration.remove()
  if (button && response.json.registered_trusted_devices_count === 0) {
    const lastRow = document.querySelector<HTMLElement>('.js-add-u2f-registration')!
    lastRow.hidden = false
  }
})

// Reveal the form the input when 'register a new device' is clicked
on('click', '.js-add-u2f-registration-link', function () {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.add('is-active')
  newDeviceBox.classList.remove('is-showing-error')
  const nickname = document.querySelector<HTMLInputElement>('.js-u2f-registration-nickname-field')!
  nickname.focus()
})

/******* Error Handling ********/

// Reveal the error message with optional custom text content.
function showError(text?: string) {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.add('is-showing-error')
  newDeviceBox.classList.remove('is-sending')

  for (const el of newDeviceBox.querySelectorAll<HTMLElement>('.js-webauthn-message')) {
    el.hidden = true
  }

  const error_container = newDeviceBox.querySelector<HTMLElement>('.js-webauthn-registration-error')!
  error_container.hidden = false

  const error_message = newDeviceBox.querySelector<HTMLElement>('.js-webauthn-registration-error-message')!
  error_message.textContent = text ?? ''
}

/******* Registration ********/

function resetForm() {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.remove('is-sending', 'is-active')
}

// Persist the client's registration response on the server.
async function saveRegistration(registerResponseJSON: PublicKeyCredentialWithAttestationJSON): Promise<void> {
  const sudoPassed = await sudo()
  if (!sudoPassed) {
    throw new Error('sudo failed')
  }

  const form = document.querySelector<HTMLFormElement>('.js-add-u2f-registration-form')!

  ;(form.elements.namedItem('response') as HTMLInputElement).value = JSON.stringify(registerResponseJSON)

  let response
  try {
    response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'}
    })
    if (!response.ok) {
      throw new Error('Response was not ok')
    }
    const data: RegistrationResponse = await response.json()
    updateDataAttribute(data)
    resetForm()
    const htmlString = data.registration
    if (htmlString) {
      const registrations = <HTMLElement>document.querySelector('.js-u2f-registrations')
      if (registrations) {
        // Security key
        registrations.append(parseHTML(document, htmlString))
      } else {
        // Trusted device
        document.querySelector<HTMLInputElement>('.js-u2f-registration-nickname-field')!.value = data.nickname || ''
        document.querySelector<HTMLDivElement>('.js-new-u2f-registration-success')!.hidden = false
        document.querySelector<HTMLDivElement>('.js-new-u2f-registration')!.hidden = true
        document.querySelector<HTMLDivElement>('.js-new-u2f-registration-never-ask')!.hidden = true
        document.querySelector<HTMLDivElement>('.js-webauthn-confirm')!.hidden = true
        document.querySelector<HTMLButtonElement>('.js-trusted-device-continue')!.focus()
      }
    }
  } catch (error) {
    if (response) {
      const errorResponse: RegistrationResponse = await response.json()
      updateDataAttribute(errorResponse)
      showError(errorResponse.error)
    } else {
      showError()
    }
  }
}

function showSpinner() {
  const newDeviceBox = document.querySelector<HTMLElement>('.js-new-u2f-registration')!
  newDeviceBox.classList.add('is-sending')
  newDeviceBox.classList.remove('is-showing-error')
}

// Ask the device to register itself when the user taps its button.
export async function waitForWebauthnDevice(): Promise<void> {
  try {
    showSpinner()

    const registerRequestJSON: CredentialCreationOptionsJSON = JSON.parse(
      u2fForm()!.getAttribute(registerRequestDataAttribute)!
    )
    const registerResponse = await create(registerRequestJSON)
    await saveRegistration(registerResponse)
  } catch (error) {
    const isSafari = u2fForm()!.classList.contains('js-u2f-safari')
    const isSafariAndNotAllowedError = isSafari && error.name === 'NotAllowedError'
    if (error.name === 'InvalidStateError' || isSafariAndNotAllowedError) {
      document.querySelector<HTMLButtonElement>('.js-new-u2f-registration')!.hidden = true
      document.querySelector<HTMLDivElement>('.js-webauthn-confirm')!.hidden = false
      document.querySelector<HTMLButtonElement>('.js-webauthn-confirm-button')!.focus()
    } else {
      showError()
      throw error
    }
  }
}

function attemptSecurityKeyRegistration(): void {
  waitForWebauthnDevice()
}

on('click', '.js-add-u2f-registration-retry', function () {
  attemptSecurityKeyRegistration()
})

// Reveal the trusted device registration form.
observe('.js-add-u2f-registration-form.for-trusted-device', {
  constructor: HTMLFormElement,
  add(el) {
    el.hidden = false
    const registerButton = document.querySelector<HTMLInputElement>('.js-trusted-device-registration-button')!
    registerButton.focus()
  }
})

// Handle the creation of a new registration.
on('submit', '.js-add-u2f-registration-form', function (event) {
  event.preventDefault()
  attemptSecurityKeyRegistration()
})

observe('.js-webauthn-box', function (el) {
  // Remove the 'unsupported' message.
  el.classList.toggle('available', supported())
})

on('auto-check-success', '.js-u2f-registration-nickname-field', function () {
  const continueButton = document.querySelector<HTMLInputElement>('.js-trusted-device-continue')!
  continueButton.disabled = false
})

on('auto-check-send', '.js-u2f-registration-nickname-field', function () {
  const continueButton = document.querySelector<HTMLInputElement>('.js-trusted-device-continue')!
  continueButton.disabled = true
})
