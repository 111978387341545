import {on} from 'delegated-events'

on('change', '.js-default-token-expiration-select', function (event) {
  const selectMenu = event.target as HTMLSelectElement
  const picker = document.getElementById('custom-expires-wrapper') as HTMLInputElement
  const formElementWrapper = document.getElementById('oauth-token-expiration') as HTMLInputElement
  const expirationHelperNoteWrapper = document.getElementById('expiration-helper-note') as HTMLInputElement
  const expirationWarning = document.getElementById('no-expiration-warning') as HTMLInputElement
  const expirationNoteContent = expirationHelperNoteWrapper.querySelector('.note') as HTMLElement

  if (selectMenu && picker) {
    if (selectMenu.value === 'custom') {
      expirationHelperNoteWrapper.setAttribute('hidden', 'hidden')
      expirationWarning.setAttribute('hidden', 'hidden')
      picker.removeAttribute('hidden')
    } else {
      picker.setAttribute('hidden', 'hidden')
      expirationHelperNoteWrapper.removeAttribute('hidden')
      formElementWrapper.classList.remove('errored')

      if (selectMenu.value === 'none') {
        expirationWarning.removeAttribute('hidden')
      } else {
        expirationWarning.setAttribute('hidden', 'hidden')
      }

      const selectedIndex = selectMenu.selectedIndex
      const selectedOption = selectMenu.children[selectedIndex]
      const humanTextAttr = selectedOption.getAttribute('data-human-date')

      if (humanTextAttr) {
        expirationNoteContent.textContent = humanTextAttr
      }
    }
  }
})
