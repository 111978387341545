import {on} from 'delegated-events'
import {requestSubmit} from '../form'

// Copy selected menu item into hidden field and schedule submit on menu close.
function selectItem(event: CustomEvent) {
  const item = event.detail.relatedTarget
  const menu = item.closest('.js-runner-bulk-menu')!
  const input = createInput(item)
  const form = menu.querySelector('.js-runner-bulk-form')!
  const existing = form.querySelector(`[value='${input.value}']`)
  if (existing) {
    existing.replaceWith(input)
  } else {
    form.append(input)
  }

  menu.addEventListener('toggle', submitOnMenuClose, {once: true})
}

function createInput(item: Element): HTMLInputElement {
  const selected = item.getAttribute('aria-checked') === 'true'

  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = selected ? 'add[]' : 'remove[]'
  input.value = item.getAttribute('data-value')!

  return input
}

function submitOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const form = currentTarget.querySelector<HTMLFormElement>('.js-runner-bulk-form')!
    requestSubmit(form)
  }
}

// Enter bulk actions mode once one item is checked.
on('change', '.js-runner-bulk-actions-toggle', function () {
  const checked = !!document.querySelector('.js-runner-bulk-actions-toggle:checked')
  document.querySelector<HTMLElement>('.js-runner-default-header')!.hidden = checked
  document.querySelector<HTMLElement>('.js-runner-bulk-actions-header')!.hidden = !checked
})

on('details-menu-selected', '.js-runner-bulk-menu', selectItem, {capture: true})
