import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'
import {requestSubmit} from '../form'

// When the user creates a new label, update the list of available labels
//to choose from, so the user can immediately apply their new label.
remoteForm('.js-create-runner-label-form', async function (form, wants) {
  let response
  const labelsMenu = form.closest<HTMLElement>('.js-runner-labels-menu')!
  const errorContainer = labelsMenu.querySelector<HTMLElement>('.js-runner-label-error')!

  try {
    response = await wants.html()
  } catch (error) {
    if (error.response.json?.message && errorContainer) {
      errorContainer.textContent = error.response.json.message
      errorContainer.hidden = false
    }

    return
  }

  if (!response || !response.html) return

  if (errorContainer) {
    errorContainer.hidden = true
    errorContainer.textContent = ''
  }

  const input = response.html.querySelector<HTMLInputElement>('.js-label-input')
  const list = labelsMenu.querySelector<HTMLElement>('.js-filterable-runner-labels')!
  list.prepend(response.html)

  // Select added label. This will trigger any listeners for label selection as well
  input?.click()

  const field = labelsMenu.querySelector<HTMLInputElement>('.js-label-filter-field')!
  field.value = ''
  field.focus()
})

function submitOnMenuClose({currentTarget}: Event) {
  if (currentTarget instanceof Element) {
    const form = currentTarget.querySelector<HTMLFormElement>('.js-runner-labels-form')!
    requestSubmit(form)
  }
}

// On item selection, add listener to submit form on menu close
on(
  'details-menu-selected',
  '.js-runner-labels-details',
  function (event) {
    const menu = event.currentTarget
    menu.addEventListener('toggle', submitOnMenuClose, {once: true})
  },
  {capture: true}
)
