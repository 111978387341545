import type AutocompleteElement from '@github/auto-complete-element'
import {addThrottledInputEventListener} from '../throttled-input'
import {fetchSafeDocumentFragment} from '../fetch'
import {observe} from 'selector-observer'
import {on} from 'delegated-events'
import {remoteForm} from '@github/remote-form'

on('change', '.js-repo-features-form input[type=checkbox]', function ({currentTarget}) {
  const repoOption = currentTarget.closest<HTMLElement>('.js-repo-option')!
  const indicator = repoOption.querySelector<HTMLElement>('.js-status-indicator')!
  indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  indicator.classList.add('status-indicator-loading')
})

remoteForm('.js-repo-features-form', async function (form, wants) {
  let response
  try {
    response = await wants.html()
  } catch (error) {
    for (const el of form.querySelectorAll('.status-indicator-loading')) {
      el.classList.remove('status-indicator-loading')
      el.classList.add('status-indicator-failed')
      const option = el.closest<HTMLElement>('.js-repo-option')!
      const checkbox = option.querySelector<HTMLInputElement>('input[type=checkbox]')!
      checkbox.checked = !checkbox.checked
    }
    return
  }
  for (const el of form.querySelectorAll('.status-indicator-loading')) {
    el.classList.remove('status-indicator-loading')
    el.classList.add('status-indicator-success')
  }

  document.querySelector<HTMLElement>('.js-repo-nav')!.replaceWith(response.html)
})

on('change', '.js-merge-features-form input[type=checkbox]', function ({currentTarget}) {
  const form = (currentTarget as HTMLInputElement).form!

  // Clear option group error states.
  for (const el of form.querySelectorAll('.errored')) {
    el.classList.remove('errored')
  }

  // Show loading indicator.
  const option = currentTarget.closest<HTMLElement>('.js-repo-option')!
  const indicator = option.querySelector<HTMLElement>('.js-status-indicator')!
  indicator.classList.remove('status-indicator-success', 'status-indicator-failed')
  indicator.classList.add('status-indicator-loading')
})

remoteForm('.js-merge-features-form', async function (form, wants) {
  try {
    await wants.text()
  } catch (error) {
    const warningSelector =
      error.response && error.response.text.endsWith('(protected_branch_policy)')
        ? '.js-no-merge-commit-warning'
        : '.js-select-one-warning'
    for (const el of form.querySelectorAll(warningSelector)) {
      if (el instanceof HTMLElement) {
        el.hidden = false
      }
    }

    for (const el of form.querySelectorAll('.status-indicator-loading')) {
      el.classList.remove('status-indicator-loading')
      el.classList.add('status-indicator-failed')

      // Show error message.
      const option = el.closest<HTMLElement>('.js-repo-option')!
      option.classList.add('errored')

      const checkbox = option.querySelector<HTMLInputElement>('input[type=checkbox]')!
      checkbox.checked = !checkbox.checked
    }

    // Clear all success indicators.
    for (const el of form.querySelectorAll('.status-indicator-success')) {
      el.classList.remove('status-indicator-success')
    }

    return
  }

  // Clear option group error states.
  for (const el of form.querySelectorAll('.errored')) {
    el.classList.remove('errored')
  }
  for (const el of form.querySelectorAll('.js-select-one-warning, .js-no-merge-commit-warning')) {
    if (el instanceof HTMLElement) {
      el.hidden = true
    }
  }

  // Show success indicator.
  for (const el of form.querySelectorAll('.status-indicator-loading')) {
    el.classList.remove('status-indicator-loading')
    el.classList.add('status-indicator-success')
  }
})

// toggle to Require status checks to pass before merging
on('change', 'input.js-required-status-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const el = container.querySelector<HTMLElement>('.js-required-statuses')!
  /* eslint-disable-next-line github/no-d-none */
  el.classList.toggle('d-none', !(currentTarget as HTMLInputElement).checked)
})

// toggle to Require deployments before merging
on('change', 'input.js-required-deployments-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const el = container.querySelector<HTMLElement>('.js-required-deployments')!
  el.hidden = !(currentTarget as HTMLInputElement).checked
})

on('change', 'input.js-required-status-checkbox', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branches-item')!
  const badge = container.querySelector<HTMLElement>('.js-required-status-badge')!
  /* eslint-disable-next-line github/no-d-none */
  badge.classList.toggle('d-none', !(currentTarget as HTMLInputElement).checked)
})

// Add required status check.
on('auto-complete-change', '.js-add-protected-branch-required-status-check', async function ({target: completer}) {
  const autocompleter = completer as AutocompleteElement
  const searchValue = autocompleter.value
  if (!searchValue) return
  autocompleter.value = ''

  const container = autocompleter.closest<HTMLElement>('.js-protected-branch-options')!
  const list = container.querySelector<HTMLElement>('.js-required-status-checks')!
  const found = list.querySelector(`li[data-context='${searchValue}']`)
  if (found) {
    found.querySelector<HTMLElement>('.js-required-status-check-context')!.classList.add('user-already-added')
    return
  }

  const dataURL = autocompleter.getAttribute('data-add-url')!
  const url = new URL(dataURL, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('item', searchValue)
  url.search = params.toString()

  list.append(await fetchSafeDocumentFragment(document, url.toString()))
})

// Remove required status check.
on('click', '.js-remove-required-status-check', function ({currentTarget}) {
  currentTarget.closest<HTMLElement>('.js-required-status-check')!.remove()
})

// Restrict who can push to this branch.
on('change', '.js-authorized-branch-pushers-toggle, input.js-authorized-review-dismisser-toggle', function ({
  currentTarget
}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')!
  const el = container.querySelector<HTMLElement>('.js-authorized-pushers')!
  /* eslint-disable-next-line github/no-d-none */
  el.classList.toggle('d-none', !(currentTarget as HTMLInputElement).checked)

  el.querySelector<HTMLInputElement>('.js-add-protected-branch-user-or-team input')!.focus()
})

// Merge queue toggle: display alert when disabling the merge queue.
on('click', '.js-protected-branch-merge-queue-toggle', function (event) {
  const checkbox = event.currentTarget as HTMLInputElement
  const msg = checkbox.getAttribute('data-confirm-if-checked') || ''

  if (!checkbox.checked && !confirm(msg)) {
    event.preventDefault()
  }

  const container = checkbox.closest<HTMLElement>('.js-protected-branch-settings')!
  const el = container.querySelector<HTMLElement>('.js-protected-branch-merge-queue-settings')!
  el.hidden = !(checkbox as HTMLInputElement).checked
})

// Enforce all configured restrictions for administrators.
on('change', '.js-protected-branch-include-admin-toggle', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-settings')!
  const permissions = container.querySelectorAll('.js-protected-branch-admin-permission')
  for (const el of permissions) {
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('d-none')
    /* eslint-disable-next-line github/no-d-none */
    el.classList.toggle('active', !el.classList.contains('d-none'))
  }
})

// Toggle the maximum number of allowed pushers.
function toggleMaxAllowedPushers(container: HTMLElement) {
  const allowedPushers = container.querySelector<HTMLElement>('.js-authorized-pushers')!
  if (!allowedPushers.getAttribute('data-limit')) return
  const allowedPushersLimit = parseInt(allowedPushers.getAttribute('data-limit') || '')
  const allowedPushersCount = allowedPushers.querySelectorAll('.js-authorized-user-or-team').length
  allowedPushers.classList.toggle('at-limit', allowedPushersCount >= allowedPushersLimit)
}

// Grant branch push access to a user.
on('auto-complete-change', '.js-add-protected-branch-user-or-team', async function ({target: completer}) {
  const autocompleter = completer as AutocompleteElement
  const searchValue = autocompleter.value
  if (!searchValue) return
  autocompleter.value = ''

  const container = autocompleter.closest<HTMLElement>('.js-protected-branch-options')!
  const list = container.querySelector<HTMLElement>('.js-authorized-users-and-teams')!
  const found = list.querySelector(`div[data-user-or-team-name='${searchValue}']`)
  if (found) {
    found.querySelector<HTMLElement>('.js-protected-branch-pusher')!.classList.add('user-already-added')
    return
  }

  const dataURL = autocompleter.getAttribute('data-add-url')!
  const url = new URL(dataURL, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  params.append('item', searchValue)
  url.search = params.toString()

  list.append(await fetchSafeDocumentFragment(document, url.toString()))
  toggleMaxAllowedPushers(container)
})

// Remove users who are authorized to push to a specific branch.
on('click', '.js-remove-authorized-user-or-team', function ({currentTarget}) {
  const container = currentTarget.closest<HTMLElement>('.js-protected-branch-options')!
  currentTarget.closest<HTMLElement>('.js-authorized-user-or-team')!.remove()
  toggleMaxAllowedPushers(container)
})

on('click', '.js-pages-cname-save-btn', function () {
  const removeButton = document.querySelector<HTMLButtonElement>('.js-pages-cname-remove-btn')!
  if (removeButton) {
    removeButton.disabled = false
  }
})

// disable pages-cname save button until value changes
observe('#pages-cname-field', {
  constructor: HTMLInputElement,
  add(el) {
    addThrottledInputEventListener(el, function () {
      const button = document.querySelector<HTMLButtonElement>('.js-pages-cname-save-btn')!
      const removeButton = document.querySelector<HTMLButtonElement>('.js-pages-cname-remove-btn')!

      if (el.value === '' && removeButton) {
        button.disabled = true
      } else {
        button.disabled = el.value === el.defaultValue
      }
    })
  }
})

// Load the HTTPS status check for Pages custom domains.
observe('.js-pages-https-status-form', {
  constructor: HTMLFormElement,
  add(form) {
    const req = new Request(form.action, {method: form.method, body: new FormData(form)})
    ;(async () => {
      try {
        const html = await fetchSafeDocumentFragment(document, req)
        form.replaceWith(html)
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // ignore
        } else {
          throw error
        }
      }
    })()
  }
})

// Query certificate status every 15 seconds.
const certificateQueryDuration = 15 * 1000
setTimeout(checkCertificateStatus, certificateQueryDuration)
async function checkCertificateStatus() {
  const content = document.querySelector('.js-pages-certificate-status')
  if (content) {
    const url = content.getAttribute('data-status-url')!
    const fragment = await fetchSafeDocumentFragment(document, url)
    content.replaceWith(fragment)
    setTimeout(checkCertificateStatus, certificateQueryDuration)
  }
}

// Query DNS 2 seconds after page load
setTimeout(checkDomainStatus, 2000)
async function checkDomainStatus() {
  const content = document.querySelector('.js-pages-domain-status')
  if (content) {
    const state = content.getAttribute('data-domain-state')!

    if (!['pending', 'queued'].includes(state)) return // if state if not pending or queued, stop checking.

    const url = content.getAttribute('data-domain-status-url')!
    const fragment = await fetchSafeDocumentFragment(document, url)
    content.replaceWith(fragment)
    // Check domain status again in 5 seconds
    setTimeout(checkDomainStatus, 5000)
  }
}

function getPageSourceSubmitBtn() {
  return document.querySelector<HTMLButtonElement>('.js-update-page-source-btn')!
}

function getPageSource(name: string) {
  const source = document.querySelector(`input[name=${name}]:checked`)
  if (source) {
    return (source as HTMLInputElement).value
  }
  return null
}

// This flash warning is only created if a gh-pages branch exists in a project repo or if we have a user repo
function getPageWarningMessage() {
  const flashWarning = document.querySelector('.js-disable-page-warning')
  if (flashWarning) {
    return flashWarning as HTMLElement
  }
  return null
}

const pageSourceBranch = getPageSource('source')
const pageSourceDir = getPageSource('source_dir')

on('change', '.js-select-branch', event => {
  const input = event.target as HTMLInputElement
  if (input.name !== 'source') return
  const sourceDirSelector = document.querySelector<HTMLElement>('.js-select-source-dir')!
  const branchIcon = document.querySelector<HTMLElement>('.js-branch-icon')!
  if (input.value !== '') {
    branchIcon.hidden = false
    sourceDirSelector.hidden = false
  } else {
    branchIcon.hidden = true
    sourceDirSelector.hidden = true
  }

  const warningObject = getPageWarningMessage()
  if (warningObject != null) {
    if (input.value === '') {
      warningObject.hidden = false
    } else {
      warningObject.hidden = true
    }
  }

  if (input.value !== pageSourceBranch) {
    getPageSourceSubmitBtn().disabled = false
    //If 'None' source is selected
    if (input.value === '' && warningObject != null) {
      getPageSourceSubmitBtn().disabled = true
    }
  } else {
    if (pageSourceDir === getPageSource('source_dir') || pageSourceBranch === '') {
      getPageSourceSubmitBtn().disabled = true
    }
  }
})

on('change', '.js-select-source-dir', event => {
  const input = event.target as HTMLInputElement
  if (input.name !== 'source_dir') return
  const sourceDir = input.value
  if (sourceDir !== pageSourceDir) {
    getPageSourceSubmitBtn().disabled = false
  } else {
    if (getPageSource('source') === pageSourceBranch) {
      getPageSourceSubmitBtn().disabled = true
    }
  }
})
